@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&display=swap');

*{
  font-family: 'Nunito', sans-serif;
}

ul{
  display: list-item !important;
}

.scroll-green::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
.ql-toolbar {
  z-index: 50;
  position: sticky !important;
}

.ql-container {
  height: 250px !important; /* whatever you what */
}

/* Track */
.scroll-green::-webkit-scrollbar-track {
 background: transparent;
}

/* Handle */
.scroll-green::-webkit-scrollbar-thumb {
  background-color: #12385C;
}