@tailwind components;

@layer components{
  .card-dark{
    @apply px-6 py-10 
    h-full
    w-full
    bg-gradient-to-l from-gray-500 to-dark 
    rounded-lg 
    shadow-md 
    transition ease-in-out
    hover:-translate-y-1 hover:scale-110
    duration-300
    cursor-pointer
    text-white;
  }

  .card-yellow{
    @apply px-6 py-10 
    w-full
    h-full
    bg-gradient-to-l from-yellow-200 to-yellow-500 
    rounded-lg 
    shadow-md
    transition ease-in-out
    hover:-translate-y-1 hover:scale-110
    duration-300
    cursor-pointer
    text-white;
  }
  
  .card-red{
    @apply px-6 py-10
    w-full
    h-full
    bg-gradient-to-l from-red-300 to-red-500 
    rounded-lg 
    shadow-md
    transition ease-in-out
    hover:-translate-y-1 hover:scale-110
    duration-300
    cursor-pointer
    text-white;
  }
  
  .card-green{
    @apply px-6 py-10
    w-full
    h-full
    bg-gradient-to-l from-green-300 to-green-500 
    rounded-lg 
    shadow-md
    transition ease-in-out
    hover:-translate-y-1 hover:scale-110
    duration-300
    cursor-pointer
    text-white;
  }
}