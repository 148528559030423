@tailwind components;

@layer components{
  .sidebar{
    @apply w-[20%] bg-dark h-full text-white;
  }
  .btn-logo{
    @apply p-4 bg-transparent h-[20%] outline-none;
  }
  .sidebar-menu{
    @apply w-full h-[80%] 
    flex flex-col 
    overflow-y-scroll scrollbar scrollbar-thumb-dark scrollbar-track-gray-100 
    py-2;
  }
}