@tailwind components;

@layer components{
  .btn-login{
    @apply bg-dark text-white font-normal w-full p-3 rounded-md hover:bg-dark/50;
  }

  .container-login{
    @apply w-full h-full flex items-center justify-center;
  }
}